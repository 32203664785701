var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.links && _vm.links.length > 1)?_c('div',{staticClass:"c-link-dropdown",class:{ 'c-link-dropdown--all-red': _vm.allRed }},[_c('div',{staticClass:"c-link-dropdown__content relative w-full h-full",class:{
			'flex-col rounded-b-none': _vm.isButtonOpen,
			'bg-white text-red': _vm.isButtonOpen && !_vm.allRed,
			'bg-red text-white': _vm.isButtonOpen && _vm.allRed,
			'border transition-colors duration-200 shadow-none':
				!_vm.isButtonOpen,
			'bg-transparent border-white text-white hover:bg-red-90':
				!_vm.isButtonOpen && !_vm.allRed,
			'bg-red border-red text-white hover:bg-red-90':
				!_vm.isButtonOpen && _vm.allRed,
		}},[_c('DropdownButton',{ref:"button",staticClass:"\n\t\t\t\tw-full\n\t\t\t\th-full\n\t\t\t\tflex\n\t\t\t\tjustify-between\n\t\t\t\titems-center\n\t\t\t\ttext-left\n\t\t\t\tpx-24\n\t\t\t\tpy-10\n\t\t\t\t>=1024:py-14\n\t\t\t",attrs:{"id":("c-link-dropdown__button-" + _vm._uid),"aria-labelledby":("c-link-dropdown__button-" + _vm._uid),"aria-owns":("c-link-dropdown__option-list-" + _vm._uid)},on:{"state:toggle":_vm.onStateToggle,"change":_vm.onChange}},[_c('span',{attrs:{"aria-hidden":_vm.descriptionIncludesLabel ? 'true' : null}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.label)+"\n\t\t\t")]),_vm._v(" "),(_vm.computedDescription)?_c('span',{staticClass:"sr-only",domProps:{"textContent":_vm._s(_vm.computedDescription)}}):_vm._e(),_vm._v(" "),_c('SvgIconWedge',{class:[
					'ml-1em w-12 h-8 mt-1',
					'transform transition-transform duration-200 ease-smooth-out',
					{ '-rotate-180 -mt-1': _vm.isButtonOpen } ]})],1),_vm._v(" "),_c('DropdownOptionList',{directives:[{name:"show",rawName:"v-show",value:(_vm.isButtonOpen),expression:"isButtonOpen"}],staticClass:"\n\t\t\t\tc-link-dropdown__option-list\n\t\t\t\tw-full\n\t\t\t\tabsolute\n\t\t\t\tpt-5xs/v\n\t\t\t\ttext-body-sm\n\t\t\t\trounded-b-30\n\t\t\t\toverflow-auto\n\t\t\t",class:{
				'bg-white text-red': !_vm.allRed,
				'bg-red text-white': _vm.allRed,
			},attrs:{"id":("c-link-dropdown__option-list-" + _vm._uid)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var activedescendant = ref.activedescendant;
			var isNavigatingByKeyboard = ref.isNavigatingByKeyboard;
return _vm._l((_vm.links),function(link,index){return _c('DropdownOption',{key:("link-" + _vm._uid + "-" + index),staticClass:"flex justify-between items-center w-full px-24",class:{
					'hover:bg-red-10': !_vm.allRed,
					'bg-red-10':
						!_vm.allRed &&
						activedescendant === ("link-" + _vm._uid + "-" + index) &&
						isNavigatingByKeyboard,
					'hover:bg-red-90': _vm.allRed,
					'bg-red-90':
						_vm.allRed &&
						activedescendant === ("link-" + _vm._uid + "-" + index) &&
						isNavigatingByKeyboard,
				},attrs:{"id":("link-" + _vm._uid + "-" + index),"value":link.name}},[_c('span',{staticClass:"\n\t\t\t\t\t\tw-full\n\t\t\t\t\t\th-full\n\t\t\t\t\t\tpy-3xs/v\n\t\t\t\t\t\tflex\n\t\t\t\t\t\tjustify-between\n\t\t\t\t\t\titems-center\n\t\t\t\t\t\tborder-t\n\t\t\t\t\t",class:{
						'border-red-20': !_vm.allRed,
						'border-white border-opacity-20': _vm.allRed,
					}},[_c('NuxtLinkExt',{class:_vm.isDownload(link)
								? 'matomo_download'
								: 'matomo_link',attrs:{"to":link.url,"target":link.target || '_blank',"download":_vm.isDownload(link) ? link.name : null,"onclick":"event.preventDefault()","tabindex":"-1"},nativeOn:{"click":function($event){return (function (event) {
								event.preventDefault();
								_vm.isDownload(link)
									? _vm.downloadMethod(link)
									: _vm.navigate(link);
							}).apply(null, arguments)}}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(link.name)+"\n\t\t\t\t\t")]),_vm._v(" "),(_vm.isDownload(link))?_c('SvgIconDownload',{staticClass:"w-16 h-16"}):_vm._e()],1)])})}}],null,false,3084131596)})],1)]):(_vm.links && _vm.links.length === 1)?_c('div',{staticClass:"c-link-dropdown",class:{ 'c-link-dropdown--all-red': _vm.allRed }},[_c('a',{staticClass:"\n\t\t\tc-link-dropdown__content\n\t\t\tborder\n\t\t\ttransition-colors\n\t\t\tduration-200\n\t\t\tw-full\n\t\t\th-full\n\t\t\tpx-24\n\t\t\tpy-10\n\t\t\t>=1024:py-14\n\t\t\tshadow-none\n\t\t",class:{
			'bg-transparent border-white text-white hover:bg-red-90':
				!_vm.allRed,
			'bg-red border-red text-white hover:bg-red-90': _vm.allRed,
			matomo_download: _vm.isDownload(_vm.links[0]),
			matomo_link: !_vm.isDownload(_vm.links[0]),
		},attrs:{"href":_vm.links[0].url,"download":_vm.isDownload(_vm.links[0]) ? _vm.links[0].name : null,"rel":"noopener noreferrer","target":_vm.links[0].target || '_blank'}},[_c('span',{attrs:{"aria-hidden":_vm.descriptionIncludesLabel ? 'true' : null}},[_vm._v("\n\t\t\t"+_vm._s(_vm.label)+"\n\t\t")]),_vm._v(" "),(_vm.computedDescription)?_c('span',{staticClass:"sr-only",domProps:{"textContent":_vm._s(_vm.computedDescription)}}):_vm._e(),_vm._v(" "),(_vm.isDownload(_vm.links[0]))?_c('SvgIconDownload',{staticClass:"w-16 h-16 ml-1em"}):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }