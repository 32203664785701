<template>
	<div
		v-if="links && links.length > 1"
		class="c-link-dropdown"
		:class="{ 'c-link-dropdown--all-red': allRed }"
	>
		<div
			class="c-link-dropdown__content relative w-full h-full"
			:class="{
				'flex-col rounded-b-none': isButtonOpen,
				'bg-white text-red': isButtonOpen && !allRed,
				'bg-red text-white': isButtonOpen && allRed,
				'border transition-colors duration-200 shadow-none':
					!isButtonOpen,
				'bg-transparent border-white text-white hover:bg-red-90':
					!isButtonOpen && !allRed,
				'bg-red border-red text-white hover:bg-red-90':
					!isButtonOpen && allRed,
			}"
		>
			<DropdownButton
				:id="`c-link-dropdown__button-${_uid}`"
				ref="button"
				:aria-labelledby="`c-link-dropdown__button-${_uid}`"
				:aria-owns="`c-link-dropdown__option-list-${_uid}`"
				class="
					w-full
					h-full
					flex
					justify-between
					items-center
					text-left
					px-24
					py-10
					>=1024:py-14
				"
				@state:toggle="onStateToggle"
				@change="onChange"
			>
				<span :aria-hidden="descriptionIncludesLabel ? 'true' : null">
					{{ label }}
				</span>
				<span
					v-if="computedDescription"
					class="sr-only"
					v-text="computedDescription"
				></span>

				<SvgIconWedge
					:class="[
						'ml-1em w-12 h-8 mt-1',
						'transform transition-transform duration-200 ease-smooth-out',
						{ '-rotate-180 -mt-1': isButtonOpen },
					]"
				/>
			</DropdownButton>

			<DropdownOptionList
				v-show="isButtonOpen"
				:id="`c-link-dropdown__option-list-${_uid}`"
				v-slot="{ activedescendant, isNavigatingByKeyboard }"
				class="
					c-link-dropdown__option-list
					w-full
					absolute
					pt-5xs/v
					text-body-sm
					rounded-b-30
					overflow-auto
				"
				:class="{
					'bg-white text-red': !allRed,
					'bg-red text-white': allRed,
				}"
			>
				<DropdownOption
					v-for="(link, index) in links"
					:id="`link-${_uid}-${index}`"
					:key="`link-${_uid}-${index}`"
					:value="link.name"
					class="flex justify-between items-center w-full px-24"
					:class="{
						'hover:bg-red-10': !allRed,
						'bg-red-10':
							!allRed &&
							activedescendant === `link-${_uid}-${index}` &&
							isNavigatingByKeyboard,
						'hover:bg-red-90': allRed,
						'bg-red-90':
							allRed &&
							activedescendant === `link-${_uid}-${index}` &&
							isNavigatingByKeyboard,
					}"
				>
					<span
						class="
							w-full
							h-full
							py-3xs/v
							flex
							justify-between
							items-center
							border-t
						"
						:class="{
							'border-red-20': !allRed,
							'border-white border-opacity-20': allRed,
						}"
					>
						<NuxtLinkExt
							:to="link.url"
							:target="link.target || '_blank'"
							:download="isDownload(link) ? link.name : null"
							:class="
								isDownload(link)
									? 'matomo_download'
									: 'matomo_link'
							"
							onclick="event.preventDefault()"
							tabindex="-1"
							@click.native="
								(event) => {
									event.preventDefault();
									isDownload(link)
										? downloadMethod(link)
										: navigate(link);
								}
							"
						>
							{{ link.name }}
						</NuxtLinkExt>
						<SvgIconDownload
							v-if="isDownload(link)"
							class="w-16 h-16"
						/>
					</span>
				</DropdownOption>
			</DropdownOptionList>
		</div>
	</div>
	<!-- Single link -->
	<div
		v-else-if="links && links.length === 1"
		class="c-link-dropdown"
		:class="{ 'c-link-dropdown--all-red': allRed }"
	>
		<a
			:href="links[0].url"
			:download="isDownload(links[0]) ? links[0].name : null"
			rel="noopener noreferrer"
			:target="links[0].target || '_blank'"
			class="
				c-link-dropdown__content
				border
				transition-colors
				duration-200
				w-full
				h-full
				px-24
				py-10
				>=1024:py-14
				shadow-none
			"
			:class="{
				'bg-transparent border-white text-white hover:bg-red-90':
					!allRed,
				'bg-red border-red text-white hover:bg-red-90': allRed,
				matomo_download: isDownload(links[0]),
				matomo_link: !isDownload(links[0]),
			}"
		>
			<span :aria-hidden="descriptionIncludesLabel ? 'true' : null">
				{{ label }}
			</span>
			<span
				v-if="computedDescription"
				class="sr-only"
				v-text="computedDescription"
			></span>

			<SvgIconDownload
				v-if="isDownload(links[0])"
				class="w-16 h-16 ml-1em"
			/>
		</a>
	</div>
</template>

<script>
import {
	DropdownButton,
	DropdownOption,
	DropdownOptionList,
} from '~/components/shared/Dropdown';
import focusOverlay from '~/assets/js/focus-overlay.js';
import SvgIconWedge from '~/assets/svgs/icon-wedge.svg?inline';
import SvgIconDownload from '~/assets/svgs/icon-download.svg?inline';

export default {
	name: 'DownloadButton',
	components: {
		SvgIconWedge,
		SvgIconDownload,
		DropdownButton,
		DropdownOption,
		DropdownOptionList,
	},
	props: {
		links: {
			type: Array,
			required: false,
		},
		label: {
			type: String,
			default: 'Se downloads',
		},
		description: {
			type: String,
			default: '',
		},
		allRed: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			isButtonOpen: false,
		};
	},
	computed: {
		descriptionIncludesLabel() {
			return this.description
				?.toLowerCase?.()
				?.includes?.(this.label?.toLowerCase?.() ?? '');
		},
		computedDescription() {
			if (
				!this.description ||
				!this.label ||
				!this.descriptionIncludesLabel
			) {
				return this.description;
			}
			return this.description
				.toLowerCase()
				.replace(this.label.toLowerCase(), this.label);
		},
	},
	methods: {
		downloadMethod({ url, name, target }) {
			const anchor = document.createElement('a');
			anchor.href = url;
			anchor.download = name;
			anchor.target = target || '_blank';
			document.body.appendChild(anchor);
			anchor.click();
			document.body.removeChild(anchor);
		},
		navigate({ url, target }) {
			const anchor = document.createElement('a');
			anchor.href = url;
			anchor.target = target || '_blank';
			document.body.appendChild(anchor);
			anchor.click();
			document.body.removeChild(anchor);
		},
		onFocus() {
			if (focusOverlay?.isKeyboardNavigation) {
				const { button } = this.$refs;
				button?.toggleOwnedLists?.(true);
			}
		},
		onStateToggle(event) {
			this.isButtonOpen = event;

			// Continue the emit
			this.$emit('state:toggle', event);
		},
		onChange() {
			const index =
				this.$refs.button?.selectedIds?.[0]?.split?.('-')?.pop?.() ??
				-1;
			const link = index >= 0 ? this.links?.[index] : null;
			if (link) {
				if (this.isDownload(link)) {
					this.downloadMethod(link);
				} else {
					this.navigate(link);
				}
			}
			this.$refs.button.toggleOwnedLists(true);
		},

		isDownload(link) {
			if (link.type === 'media') {
				return true;
			}
			return false;
		},
	},
};
</script>

<style lang="postcss">
.c-link-dropdown__content {
	@apply inline-flex items-center justify-center rounded-25;
	@apply text-body leading-1.2;
	box-shadow: 0px 4px 24px 2px rgba(86, 86, 86, 0.12);
	position: relative;
	z-index: 5;
}
.c-link-dropdown__content:hover {
	cursor: pointer;
}

.c-link-dropdown__option-list {
	top: 100%;
}
</style>
