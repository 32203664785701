import { camelCase, upperFirst } from 'lodash';

const BLOCKS_BY_SOLUTION_AND_ALIAS = {
	// Plop: block-list - add solution
	main: {
		// Plop: block-list - add main block
		experts: () =>
			import(
				/* webpackChunkName: "main__blockListExperts" */ '~/components/main/BlockList/blocks/BlockListExperts.vue'
			),
		contactPersons: () =>
			import(
				/* webpackChunkName: "main__blockListContactPersons" */ '~/components/main/BlockList/blocks/BlockListContactPersons.vue'
			),
		contactPersonExternal: () =>
			import(
				/* webpackChunkName: "main__blockListContactPersons" */ '~/components/main/BlockList/blocks/BlockListContactPersons.vue'
			),
		relatedContent: () =>
			import(
				/* webpackChunkName: "main__blockListRelatedContent" */ '~/components/main/BlockList/blocks/BlockListRelatedContent.vue'
			),
		BlockNewsletter: () =>
			import(
				/* webpackChunkName: "main__blockListNewsletter" */ '~/components/main/BlockList/blocks/BlockListNewsletter.vue'
			),
		spotBox: () =>
			import(
				/* webpackChunkName: "main__blockListSpotBox" */ '~/components/main/BlockList/blocks/BlockListSpotBox.vue'
			),
		table: () =>
			import(
				/* webpackChunkName: "main__blockListTable" */ '~/components/main/BlockList/blocks/BlockListTable.vue'
			),
		cta: () =>
			import(
				/* webpackChunkName: "main__blockListCta" */ '~/components/main/BlockList/blocks/BlockListCta.vue'
			),
		ctaAccordion: () =>
			import(
				/* webpackChunkName: "main__blockListCta" */ '~/components/main/BlockList/blocks/BlockListCta.vue'
			),
		quote: () =>
			import(
				/* webpackChunkName: "main__blockListQuote" */ '~/components/main/BlockList/blocks/BlockListQuote.vue'
			),
		images: () =>
			import(
				/* webpackChunkName: "main__blockListImages" */ '~/components/main/BlockList/blocks/BlockListImages.vue'
			),
		imagesAccordion: () =>
			import(
				/* webpackChunkName: "main__blockListImages" */ '~/components/main/BlockList/blocks/BlockListImages.vue'
			),
		video: () =>
			import(
				/* webpackChunkName: "main__blockListVideo" */ '~/components/main/BlockList/blocks/BlockListVideo.vue'
			),
		videoAccordion: () =>
			import(
				/* webpackChunkName: "main__blockListVideo" */ '~/components/main/BlockList/blocks/BlockListVideo.vue'
			),
		accordion: () =>
			import(
				/* webpackChunkName: "main__blockListAccordion" */ '~/components/main/BlockList/blocks/BlockListAccordion.vue'
			),
		richTextEditor: () =>
			import(
				/* webpackChunkName: "main__blockListRte" */ '~/components/main/BlockList/blocks/BlockListRte.vue'
			),
		richTextEditorAccordion: () =>
			import(
				/* webpackChunkName: "main__blockListRte" */ '~/components/main/BlockList/blocks/BlockListRte.vue'
			),
		iframe: () =>
			import(
				/* webpackChunkName: "main__blockListIframe" */ '~/components/main/BlockList/blocks/BlockListIframe.vue'
			),
		infogram: () =>
			import(
				/* webpackChunkName: "main__blockListInfogram" */ '~/components/main/BlockList/blocks/BlockListInfogram.vue'
			),
	},
	shared: {
		// Plop: block-list - add shared block
	},
};

export const getBlockNameFromAlias = (alias) =>
	`BlockList${upperFirst(camelCase(alias))}`;

export const getBlocksBySolution = (solution) =>
	Object.entries(BLOCKS_BY_SOLUTION_AND_ALIAS[solution] ?? {}).reduce(
		(acc, [alias, component]) => ({
			...acc,

			[getBlockNameFromAlias(alias)]: component,
		}),
		{}
	);
